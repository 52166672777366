import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import ButtonWrapper from "../ButtonWrapper";
import Header from "../../../components/marginals/Header";
import Footer from "../../../components/marginals/Footer";
import useStateWithLocalStorage from "../../../helpers/useStateWithLocalStorage";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function Checkout() {
  const { t, i18n } = useTranslation();
  const currency = "USD";
  const [token, setToken] = useStateWithLocalStorage("token");
  const [documentId, setDocumentId] = useStateWithLocalStorage("documentId");

  const localesMap = {
    en: "en_IL",
    he: "he_IL",
    ar: "ar_EG",
  }

  const locale = localesMap[i18n.language] ? localesMap[i18n.language] : localesMap[0]

  useEffect(() => {
    document.title = t("checkout_title")
  }, []);

  return (
    <>
      <Header />
      <div className="d-flex justify-content-center mt-5">
        <PayPalScriptProvider
          options={{
            "client-id":
              process.env.NODE_ENV === "production"
                ? process.env.REACT_APP_PAYPAL_CLIENT_ID_PROD
                : process.env.REACT_APP_PAYPAL_CLIENT_ID_SANDBOX,
            components: "buttons",
            currency: "USD",
            locale: locale,
          }}
        >
          <ButtonWrapper
            currency={currency}
            showSpinner={false}
          />
        </PayPalScriptProvider>
      </div>
      <Footer />
    </>
  );
}
